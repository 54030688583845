import { createPinia, defineStore } from 'pinia'
import { GlobalState, ThemeConfigProp } from './interface'
import piniaPersist from 'pinia-plugin-persist'
import piniaPersistConfig from '@/config/piniaPersist'
import { setToken, setParentId } from '@/utils/auth'

// defineStore 调用后返回一个函数，调用该函数获得 Store 实体
export const GlobalStore = defineStore({
  // id: 必须的，在所有 Store 中唯一
  id: 'GlobalState',
  // state: 返回对象的函数
  state: (): GlobalState => ({
    // token
    token: '',
    parentId: '2000',
    refreshToken: '',
    appid: '',
    // userInfo
    userInfo: '',
    // element组件大小
    assemblySize: 'default',
    // language
    language: 'zh',
    // themeConfig
    themeConfig: {
      // 默认 primary 主题颜色
      primary: '#409eff',
      // 是否开启深色模式
      isDark: false,
      isGrey: false,
      isWeak: false,
      breadcrumb: false,
      tabs: false,
      footer: false
    }
  }),
  getters: {},
  actions: {
    // setToken
    setToken(token: string) {
      setToken(token)
      this.token = token
    },
    setParentId(parentId: string) {
      setParentId(parentId)
      this.parentId = parentId
		 },
    setRefreshToken(refreshToken: string) {
      this.refreshToken = refreshToken
    },
    setAppid(appid: string) {
      this.appid = appid
    },
    // setUserInfo
    setUserInfo(userInfo: any) {
      this.userInfo = userInfo
    },
    // setAssemblySizeSize
    setAssemblySizeSize(assemblySize: string) {
      this.assemblySize = assemblySize
    },
    // updateLanguage
    updateLanguage(language: string) {
      this.language = language
    },
    // setThemeConfig
    setThemeConfig(themeConfig: ThemeConfigProp) {
      this.themeConfig = themeConfig
    }
  },
  persist: piniaPersistConfig('GlobalState')
})

// piniaPersist(持久化)
const pinia = createPinia()
pinia.use(piniaPersist)

export default pinia
