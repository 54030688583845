// ? 全局不动配置项 只做导出不做修改

// * 首页地址（默认）
export const HOME_URL = '/index'

// * 默认主题颜色
export const DEFAULT_PRIMARY = '#45D3A7'

// * Tabs（黑名单地址，不需要添加到 tabs 的路由地址）
export const TABS_BLACK_LIST: string[] = ['/403', '/404', '/500', '/layout', '/login']

// * 高德地图key
export const MAP_KEY = '20f12aae660c04de86f993d3eff590a0'
