/* eslint-disable no-unused-vars */
/**
 * @description 检验类型
 * @author wx
 */

export enum ExamineTypeEnum {
  /** 检验项目 */
  EXAMINE = 'examine',
  /** 检查项目 */
  INSPECT = 'inspect'
}
