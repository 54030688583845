import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/mall',
    component: Layout,
    redirect: '/mall/index',
    meta: {
      title: '产品采购',
      keepAlive: true,
      requiresAuth: true,
      auth: ['procurement']
    },
    children: [
      {
        path: 'index',
        name: 'MallIndex',
        component: () => import('@/views/app/mall/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '产品采购',
        }
      },
      {
        path: 'search',
        name: 'MallSearch',
        component: () => import('@/views/app/mall/search.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '产品采购',
        }
      },
      {
        path: 'detail',
        name: 'MallDetail',
        component: () => import('@/views/app/mall/detail/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '产品详情',
        }
      },
      {
        path: 'cart',
        name: 'MallCart',
        component: () => import('@/views/app/mall/cart/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '购物车'
        }
      },
      {
        path: 'order',
        name: 'MallOrder',
        component: () => import('@/views/app/mall/order/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '订单管理'
        }
      }
    ]
  }
]

export default router
