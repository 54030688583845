// * 请求枚举配置
/**
 * @description：请求配置
 */
export enum ResultEnum {
	// eslint-disable-next-line no-unused-vars
	SUCCESS = 200,
	// eslint-disable-next-line no-unused-vars
	ERROR = 500,
	// eslint-disable-next-line no-unused-vars
	OVERDUE = 424,
	// eslint-disable-next-line no-unused-vars
	TIMEOUT = 30000,
	// eslint-disable-next-line no-unused-vars
	TYPE = 'success'
}

/**
 * @description：请求方法
 */
export enum RequestEnum {
	// eslint-disable-next-line no-unused-vars
	GET = 'GET',
	// eslint-disable-next-line no-unused-vars
	POST = 'POST',
	// eslint-disable-next-line no-unused-vars
	PATCH = 'PATCH',
	// eslint-disable-next-line no-unused-vars
	PUT = 'PUT',
	// eslint-disable-next-line no-unused-vars
	DELETE = 'DELETE'
}

/**
 * @description：常用的contentTyp类型
 */
export enum ContentTypeEnum {
	// json
	// eslint-disable-next-line no-unused-vars
	JSON = 'application/json;charset=UTF-8',
	// text
	// eslint-disable-next-line no-unused-vars
	TEXT = 'text/plain;charset=UTF-8',
	// form-data 一般配合qs
	// eslint-disable-next-line no-unused-vars
	FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
	// form-data 上传
	// eslint-disable-next-line no-unused-vars
	FORM_DATA = 'multipart/form-data;charset=UTF-8'
}
