<template>
  <el-image :src="imageSrc" :preview-src-list="previewSrcList" :fit="fit" v-bind="$attrs">
    <template #error>
      <slot name="error">
        <div class="image-slot">
          <el-icon><icon-picture></icon-picture></el-icon>
        </div>
      </slot>
    </template>
    <template #placeholder>
      <div class="image-slot">
        加载中<span class="dot">...</span>
      </div>
    </template>
  </el-image>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Picture as IconPicture } from '@element-plus/icons-vue'

interface IProps {
  src: string | undefined;
  previews?: string[] | undefined;
  fit?: string | undefined;
}

const props = withDefaults(defineProps<IProps>(), {
  previews: () => [],
  fit: 'cover'
})

// oss 地址
const ossPath = import.meta.env.VITE_OSS_PATH

const imageSrc = computed(() => {
  if (props.src) {
    if (props.src.includes('http')) {
      return props.src
    } else {
      return ossPath + props.src
    }
  }
  return ''
})

const previewSrcList = computed(() => {
  const images: string[] = []
  props.previews.forEach(image => {
    if (image && image.includes('http')) {
      images.push(image)
    } else {
      images.push(ossPath + image)
    }
  })
  return images
})

</script>
<style lang="scss">
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--el-text-color-secondary);
  background: var(--el-fill-color-light);
}
 .image-slot .el-icon {
  font-size: 30px;
}
</style>
