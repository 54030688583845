// import directives
import { App } from 'vue'
import copy from './modules/copy'
import permission from './modules/permission'
import waterMarker from './modules/waterMarker'
import draggable from './modules/draggable'
import debounce from './modules/debounce'
import throttle from './modules/throttle'
import longpress from './modules/longpress'
import renewal from './modules/renewal'

const directivesList: any = {
  // Custom directives
  copy,
  permission,
  waterMarker,
  draggable,
  debounce,
  throttle,
  longpress,
  renewal
}

const directives = {
  install: function(app: App<Element>) {
    Object.keys(directivesList).forEach(key => {
      // 注册自定义指令
      app.directive(key, directivesList[key])
    })
  }
}

export default directives
