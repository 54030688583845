import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/nursing',
    component: Layout,
    redirect: '/nursing/index',
    meta: {
      title: '上门接单',
      keepAlive: true,
      requiresAuth: false,
      menu: true,
      auth: ['home_care']
    },
    children: [
      {
        path: 'index',
        name: 'NursingIndex',
        component: () => import('@/views/app/nursing/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '医护设置',
          auth: ['home_care'],
          menu: true,
        }
      },
      {
        path: 'order',
        name: 'NursingOrder',
        component: () => import('@/views/app/order/nursing/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '医护订单',
          menu: true,
          auth: ['home_care']
        }
      }
    ]
  }
]

export default router
