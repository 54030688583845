import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/doctor',
    component: Layout,
    redirect: '/doctor/schedule',
    meta: {
      title: '大家医生',
      keepAlive: true,
      requiresAuth: true,
      auth: ['djys']
    },
    children: [
      {
        path: 'schedule',
        name: 'AccountSchedule',
        component: () => import('@/views/app/account/schedule/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '预约挂号排班',
          menu: true,
          auth: ['djys']
        }
      },
      {
        path: 'goods',
        name: 'OrderGoods',
        component: () => import('@/views/app/order/goods/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '商品代理订单',
          menu: true,
          auth: ['marketplace_agent'],
        }
      },
      {
        path: 'inspection',
        name: 'DoctorOrderInspection',
        component: () => import('@/views/app/order/inspection/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '检验核销订单',
          menu: true
        }
      },
      {
        path: 'materialOrder',
        name: 'OrderMaterialOrder',
        component: () => import('@/views/app/order/materialOrder/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '提货核销订单',
          menu: true
        }
      },
      {
        path: 'service',
        name: 'OrderService',
        component: () => import('@/views/app/order/service/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '服务核销订单',
          menu: true
        }
      },
      {
        path: 'appointment',
        name: 'OrderAppointment',
        component: () => import('@/views/app/order/appointment/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '挂号核销订单',
          menu: true
        }
      },
      {
        path: 'feePayment',
        name: 'OrderFeePayment',
        component: () => import('@/views/app/order/feePayment/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '门店缴费订单',
          menu: true
        }
      }
    ]
  }
]

export default router
