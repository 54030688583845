import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/inspection',
    component: Layout,
    redirect: '/inspection/index',
    meta: {
      title: '标本送检',
      keepAlive: true,
      requiresAuth: true,
      menu: true,
      auth: ['inspection']
    },
    children: [
      {
        path: 'index',
        name: 'InspectionIndex',
        component: () => import('@/views/app/inspection/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '在线开单',
          menu: true
        }
      },
      {
        path: 'inspection',
        name: 'OrderInspection',
        component: () => import('@/views/app/order/inspection/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '检验检查订单',
          menu: true,
          auth: ['inspection']
        }
      },
      {
        path: 'coupon',
        name: 'OrderCoupon',
        component: () => import('@/views/app/order/coupon/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '卡券订单',
          menu: true
        }
      },
      {
        path: 'recharge',
        name: 'OrderRecharge',
        component: () => import('@/views/app/order/recharge/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '充值订单',
          menu: true
        }
      },
      {
        path: 'submit',
        name: 'InspectionSubmit',
        component: () => import('@/views/app/inspection/submit/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '提交订单',
          activeMenu: '/inspection/index'
        }
      },
      {
        path: 'patient',
        name: 'InspectionPatient',
        component: () => import('@/views/app/patient/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '患者管理',
          menu: true,
        }
      }
    ]
  }
]

export default router
