import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token:string) {
  return Cookies.set(TokenKey, token, { domain: 'qnyt.gzyouth.cn' })
}

export function setParentId(parentId:string) {
  return Cookies.set('menuParentId', parentId)
}

export function getParentId() {
  return Cookies.get('menuParentId')
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
