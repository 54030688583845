export default {
  home: {
    welcome: '欢迎使用'
  },
  tabs: {
    more: '更多',
    closeCurrent: '关闭当前',
    closeOther: '关闭其它',
    closeAll: '关闭所有'
  },
  header: {
    componentSize: '组件大小',
    language: '语言',
    theme: '主题',
    themeSetting: '主题设置',
    darkMode: '暗黑模式',
    lightMode: '浅色模式',
    fullScreen: '全屏',
    exitFullScreen: '退出全屏',
    personalData: '个人资料',
    changePassword: '修改密码',
    logout: '退出登录'
  }
}
