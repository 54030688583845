<script lang='ts' setup>
import { computed, useSlots } from 'vue'

interface IProps{
	prefix?: string;
	emptyText?: string;
}

withDefaults(defineProps<IProps>(), {
  prefix: '',
  emptyText: '--'
})

const isEmpty = computed(() => {
  const slots = useSlots()
  if (slots.default) {
    return !slots.default().every(item => item.children === '')
  }
  return false
})
</script>
<template>
  <el-text v-if="isEmpty" v-bind="$attrs">{{ prefix }}<slot></slot></el-text>
  <el-text v-else v-bind="$attrs">{{ emptyText }}</el-text>
</template>
<style lang='scss' scoped>
</style>
