import { PORT1, ADMIN } from '@/api/config/servicePort'
import http from '@/api'
import { IDict, IRegion, IExamineCategory, ISpecialist, ISysActivity, IAdvert, SearchHighlight } from '@/models'
import { ResPage, ReqPage } from '../interface'

/** 获取字典列表 */
export const getDictList = (params: { type: string; t?: number }) => {
  return http.get<Array<IDict.Model>>(PORT1 + `/admin/dict/type/${params.type}`, params, { bfLoading: false })
}

/**  权限区域树 */
export const getRegionList = (params?: { deptId?: string; roleId?: string }) => {
  return http.get<Array<IRegion.Model>>(PORT1 + `/admin/region/tree`, params)
}

/**  检验分类 */
export const getExamineCategory = (params?: { categoryName?: string; status?: string; institutionId?: string }) => {
  return http.get<Array<IExamineCategory.Model>>(ADMIN + `/sys-institution-examine-category/tree`, params, { bfLoading: false })
}

/**  服务费 */
export const getDeliveryFees = () => {
  return http.get<{deliveryFeesType: string; price: number}>(ADMIN + `/app/sys-delivery-fees/fees`, {}, { bfLoading: false })
}

/**  专家分页 */
export const getSpecialistPage = (params: Partial<ISpecialist.Model> & ReqPage) => {
  return http.get<ResPage<ISpecialist.Model>>(ADMIN + `/specialist/page`, params)
}

/**  充值活动 */
export const getRechargeActivityForGoldBeans = () => {
  return http.get<Array<ISysActivity.Model>>(ADMIN + `/sys-activity/list`)
}

// 获取广告位列表
export const getAdvertList = (params: { locationNo: string; cityId?: string }) => {
  const header = {
    isToken: false,
  }
  return http.get<Array<IAdvert.Model>>('' + `/cms/app/advert/list`, params, { header })
}

/**
 * 联想查询
 * @param params
 * @returns
 */
export const getSearchHighlightList = (params: {keyword: string, institutionId: string; size: number; }) => {
  const header = {
    isToken: false,
  }
  return http.get<SearchHighlight[]>('' + `/es/institution-examine/search-list`, params, { header, bfLoading: false })
}
