import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/payments',
    component: Layout,
    redirect: '/payments/index',
    meta: {
      title: '支付订单',
      keepAlive: true,
      requiresAuth: false,
      hidden: true
    },
    children: [
      {
        path: 'index',
        name: 'PaymentsIndex',
        component: () => import('@/views/app/payments/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '支付订单',
        }
      },
      {
        path: 'result',
        name: 'PaymentsResult',
        component: () => import('@/views/app/payments/result.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '支付订单',
        }
      }
    ]
  }
]

export default router
