import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/report',
    component: Layout,
    redirect: '/report/index',
    meta: {
      title: '报告管理',
      keepAlive: true,
      requiresAuth: false,
      auth: ['inspection', 'inspection_verification']
    },
    children: [
      {
        path: 'index',
        name: 'ReportIndex',
        component: () => import('@/views/app/report/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '报告管理',
        }
      },
      {
        path: 'detail',
        name: 'ReportDetail',
        component: () => import('@/views/app/report/detail/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '报告管理',
        }
      }
    ]
  }
]

export default router
