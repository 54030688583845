import { createApp } from 'vue'

import App from './App.vue'
// reset style sheet
import '@/styles/reset.scss'
// CSS common style sheet
import '@/styles/common.scss'
// iconfont css
import '@/assets/iconfont/iconfont.scss'
import '@/assets/iconfont/djjkIcon.scss'
// font css
import '@/assets/fonts/font.scss'
// element plus
import ElementPlus from 'element-plus'
// element icons
import * as Icons from '@element-plus/icons-vue'
// element css
import 'element-plus/dist/index.css'
// element dark
import 'element-plus/theme-chalk/dark/css-vars.css'
// custom element dark
import '@/styles/element-dark.scss'
// custom element css
import '@/styles/element.scss'
// 主题色
import '@/styles/color.scss'
// custom directives
import directives from '@/directives/index'
// vue Router
import router from '@/routers/index'
// i18n
import I18n from '@/language/index'
// pinia store
import pinia from '@/store/index'
// icon-park
import '@icon-park/vue-next/styles/index.css'

import UseImage from '@/components/MyImage/index.vue'
import UseDictTag from '@/components/MyDict/tag.vue'
import UseIcon from '@/components/MyIcon/index.vue'
import UseText from '@/components/UseText/index.vue'
// import { loadStyle } from './utils/util'

// const iconfontUrl = ['//at.alicdn.com/t/font_667895_v7uduh4zui.css', '//at.alicdn.com/t/c/font_1638883_9yea0jvav1.css']
// // 动态加载阿里云字体库
// iconfontUrl.forEach(ele => {
//   loadStyle(ele)
// })

const app = createApp(App)
app.use(pinia)
// 注册element Icons组件
Object.keys(Icons).forEach(key => {
  app.component(key, Icons[key as keyof typeof Icons])
})

app.component('UseImage', UseImage)
app.component('UseDictTag', UseDictTag)
app.component('UseIcon', UseIcon)
app.component('UseText', UseText)

app.use(router).use(I18n).use(directives).use(ElementPlus).mount('#app')
