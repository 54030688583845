/**
 * v-permission
 * 校验按钮权限
 * 接收参数：string[]
 */
import type { Directive, DirectiveBinding } from 'vue'
import { AuthStore } from '@/store/modules/auth'
const permission: Directive = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding)
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding)
  }
}

function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { value } = binding
  const authStore = AuthStore()
  const permission = authStore.authButtons
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value

      const hasPermission = permission.some(role => {
        return permissionRoles.includes(role) || permissionRoles.includes(false)
      })

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  } else {
    throw new Error(`need roles! Like v-permission="['admin','editor']"`)
  }
}

export default permission
