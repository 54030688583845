import { ElMessage } from 'element-plus'
import router from '@/routers'
import { GlobalStore } from '@/store'
/**
 * @description: 校验状态码
 * @param {number} status
 */
export const checkStatus = (status: number, message: string): void => {
  const { setToken } = GlobalStore()
  switch (status) {
    case 400:
    case 428:
      ElMessage.error(message)
      break
    case 401:
      ElMessage.error('用户名或密码错误')
      break
    case 424:
      ElMessage.error('登录失效！请您重新登录')
      setToken('')
      router.replace({ path: '/login' })
      break
    case 403:
      ElMessage.error('当前账号无权限访问！')
      break
    case 404:
      ElMessage.error('你所访问的资源不存在！')
      router.replace({ path: '/404' })
      break
    case 405:
      ElMessage.error('请求方式错误！请您稍后重试')
      break
    case 408:
      ElMessage.error('请求超时！请您稍后重试')
      break
    case 500:
      ElMessage.error('服务异常！')
      // router.replace({ path: '/500' })
      break
    case 502:
      ElMessage.error('网关错误！')
      break
    case 503:
      ElMessage.error('服务不可用！')
      break
    case 504:
      ElMessage.error('网关超时！')
      break
    default:
      ElMessage.error('请求失败！')
  }
}
