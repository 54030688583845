import {
  computed,
  reactive,
  toRefs
} from 'vue'

import { defineStore } from 'pinia'

import piniaPersistConfig from '@/config/piniaPersist'
import { ExamineTypeEnum } from '@/models/institution/enums'
import { IInstitution } from '@/models/institution/interface'

interface globalDataStore {
  examineProList: Array<IInstitution.ExaminePro>;
	laboratory: IInstitution.Model | null;
	currentClinic: {deptId: string; deptName: string} | null;
	pageRefresh: {
		[key: string]: {
			status: boolean
		}
	}
}

export const useGlobalDataStore = defineStore('globalDataStore', () => {
  const state = reactive<globalDataStore>({
    examineProList: [],
    laboratory: null,
    currentClinic: null,
    pageRefresh: {
      InspectionIndex: {
        status: false
      }
    }
  })

  const getExamineProList = computed(() => {
    return state.examineProList
  })

  const getExamineList = computed(() => {
    return state.examineProList.filter(item => item.examineType === ExamineTypeEnum.EXAMINE)
  })

  const getInspectList = computed(() => {
    return state.examineProList.filter(item => item.examineType === ExamineTypeEnum.INSPECT)
  })

  const getCurrentClinic = computed(() => {
    return state.currentClinic
  })

  const addExaminePro = (data: IInstitution.ExaminePro) => {
    state.examineProList.push(data)
  }

  const deleteExaminePro = (index: number) => {
    state.examineProList.splice(index, 1)
  }

  const clearExaminePro = () => {
    state.examineProList.splice(0)
  }

  const getLaboratory = computed(() => {
    return state.laboratory
  })

  const setLaboratory = (data: IInstitution.Model) => {
    state.laboratory = data
  }

  const setPageRefreshStatus = (pageName: string, status: boolean) => {
    state.pageRefresh[pageName].status = status
  }

  const setCurrentClinic = (data: {deptId: string; deptName: string}) => {
    state.currentClinic = data
  }

  return {
    ...toRefs(state),
    getExamineProList,
    getExamineList,
    getInspectList,
    addExaminePro,
    deleteExaminePro,
    clearExaminePro,
    getLaboratory,
    setLaboratory,
    setPageRefreshStatus,
    setCurrentClinic,
    getCurrentClinic
  }
}, {
  persist: piniaPersistConfig('globalDataStore')
})
